<template>
  <div id="main-wrapper" class="main-wrapper">
      <section class="account-page-area section-gap-equal">
          <div class="container position-relative">
              <div class="row g-5 justify-content-center">
                  <div class="col-lg-5">
                      <div class="login-form-box">
                          <h3 class="title">{{  $t('Login to your account') }}</h3>
                          <form>

                              <div class="form-group">
                                  <button @click="goToLogin" type="button" class="edu-btn btn-medium">{{ $t('Log in') }} <i class="icon-4"></i></button>
                              </div>
                              <a href="#" @click="goToForgotPassword">{{ $t("Forgot your password?") }}</a>
                          </form>
                      </div>
                  </div>
                  <div class="col-lg-5">
                      <div class="login-form-box registration-form">
                          <h3 class="title">{{  $t('Create a new account') }}</h3>
                          <form @submit.prevent v-if="!hasPendingValidation">
                              <div class="form-group">
                                  <label for="reg-name">{{  $t('Enter your phone number for verification') }}</label>
                                  <div class="phone-input">
                                    <Multiselect v-model="$v.countryCode.$model" :class="{'is-invalid': $v.countryCode.$error}" :multiple="false" :show-labels="false" label="dial_code" track-by="dial_code"  :internal-search="false" @search-change="findCountry" :options="filteredCountriesList" placeholder="+XX">
                                        <template slot="option" slot-scope="{ option }">
                                          {{ option.name }} ({{  option.dial_code }})
                                        </template>
                                        <template slot="singleLabel" slot-scope="{ option }">
                                          {{ option.dial_code }}
                                        </template>
                                      </Multiselect>
                                      <input v-model="$v.phoneNumber.$model" class="phone-input__input"  :class="{'is-invalid': $v.phoneNumber.$error}" type="text" placeholder="Phone number" @keyup.prevent.enter="sendCode">
                                    <div class="invalid-feedback">
                                      Please enter valid phone number
                                    </div>
                                  </div>
                              </div>
                              <div class="form-group">
                                  <button type="button" class="edu-btn btn-medium" @click="sendCode">Send Code <i
                                          class="icon-4"></i></button>
                              </div>
                          </form>
                          <form @submit.prevent v-else>
                            <div class="form-group">
                                  <label for="validation-code">Validation code</label>
                                  <input ref="validationCode" id="validation-code" v-model="$v.validationCode.$model" :class="{'is-invalid': $v.validationCode.$error}" type="text" placeholder="Validation code" @keyup.prevent.enter="validateCode">
                                  <div class="invalid-feedback">
                                    Please enter valid code
                                  </div>
                              </div>
                              <div class="form-group">
                                  <button type="button" class="edu-btn btn-medium" @click="validateCode">Validate Code <i
                                          class="icon-4"></i></button>
                              </div>
                              <div v-if="validationCodeError" class="alert alert-danger" role="alert">
                                {{  $t('Invalid validation code') }}
                              </div>
                              <a href="#" @click="dropValidation">Didn't got the code? Try another one</a>
                          </form>
                      </div>
                  </div>
              </div>
          </div>
      </section>
  </div>
</template>

<script>
import { routingUtils, DomainName } from 'coderz-utils/index-no-deps'

import { required, minLength, maxLength, numeric } from 'vuelidate/lib/validators'

import Multiselect from 'vue-multiselect'

export default {
  components: {
    Multiselect
  },
  data () {
    return {
      validationCodeError: null,
      filteredCountriesList: []
    }
  },
  methods: {
    goToLogin () {
      routingUtils.goBackToLogin('/')
    },
    goToForgotPassword () {
      window.location.href = `${window.location.protocol}//account.${DomainName}/forgot-pass`
    },
    async sendCode () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        // console.log('invalid!')
      } else {
        try {
          await this.$store.dispatch('user/sendValidationCode', {
            dialCode: this.countryCode.dial_code,
            phoneNumber: this.phoneNumber
          })
        } catch (e) {
          this.validationCodeError = e
        }
      }
    },
    async validateCode () {
      this.$v.$touch()
      this.validationCodeError = false
      if (this.$v.$invalid) {
        // console.log('invalid!')
      } else {
        const res = await this.$store.dispatch('user/verifyValidationCode', {
          code: this.validationCode
        })
        if (!res) {
          this.validationCodeError = true
        } else {
          this.$router.push({ name: 'main' })
        }
      }
    },
    async dropValidation () {
      this.$store.dispatch('user/dropPendingValidation')
    },
    findCountry (searchPattern) {
      const allCountries = [...this.countriesList]

      this.filteredCountriesList = allCountries.filter((c) => {
        return c.name.toLowerCase().includes(searchPattern.toLowerCase()) || c.dial_code.includes(searchPattern)
      })
    }
  },
  mounted () {
    this.filteredCountriesList = [...this.countriesList]

    if (this.hasPendingValidation) {
      this.$nextTick(() => {
        this.$refs.validationCode.focus()
      })
    }
  },
  computed: {
    countriesList () {
      return this.$store.state.user.countriesList.filter(c => c.dial_code)
    },
    countryCode: {
      get () {
        return this.$store.state.user.loginFormData.countryCode
      },
      set (value) {
        this.$store.commit('user/SET_LOGIN_FORM_VALUE', { key: 'countryCode', value })
      }
    },
    phoneNumber: {
      get () {
        return this.$store.state.user.loginFormData.phoneNumber
      },
      set (value) {
        this.$store.commit('user/SET_LOGIN_FORM_VALUE', { key: 'phoneNumber', value })
      }
    },
    validationCode: {
      get () {
        return this.$store.state.user.loginFormData.validationCode
      },
      set (value) {
        this.$store.commit('user/SET_LOGIN_FORM_VALUE', { key: 'validationCode', value })
      }
    },
    hasPendingValidation () {
      return this.$store.state.user.hasPendingValidation
    }
  },
  validations () {
    if (this.hasPendingValidation) {
      return {
        validationCode: {
          required,
          minLength: minLength(6),
          maxLength: maxLength(6),
          numeric
        }
      }
    } else {
      return {
        countryCode: {
          required
        },
        phoneNumber: {
          required,
          minLength: minLength(7),
          maxLength: maxLength(12),
          numeric
        }
      }
    }
  },
  watch: {
    hasPendingValidation () {
      this.$nextTick(() => {
        this.$refs.validationCode.focus()
      })
    }
  }
}
</script>

<style lang="scss">
.phone-input {
  display: flex;
  margin-bottom: 24px;
  .invalid-feedback {
    position: absolute;
    bottom: -24px;
  }

  &__input {
    height: 45px !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-left: 0 !important;
  }

  .multiselect {
    width: 128px;
  }
  .multiselect__content-wrapper {
    width: 370px;
  }

  .multiselect__placeholder {
  }

  .multiselect__input {

  }

  .multiselect__single {
    left: 10px;
  }

  .multiselect__tags {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}
</style>
