<template>
  <div id="main-wrapper" class="main-wrapper success-page">
    <section class="section-gap-equal error-page-area">
      <div class="container">
          <div class="edu-error">
              <div v-if="successType === 'paid'" class="content">
                  <h2 class="title">Thank you for your recent order with CoderZ!</h2>
                  <h4 class="subtitle">
                    Your invoice has been sent to your email!</h4>
                    <h4 class="subtitle">Please check your inbox, and if you don't see it, it might be in your spam folder.</h4>
                    <h4 class="subtitle">
                    For any questions or assistance, contact us at <a href="mailto:contactus@gocoderz.com.">contactus@gocoderz.com</a>.</h4>
                    <h4 class="subtitle">
                      Thank you for becoming a part of the CoderZ community.
                    </h4>
                    <h4 class="subtitle">
                      We look forward to accompanying you on your journey of learning and growth.
                    </h4>
              </div>
              <div v-else-if="successType === 'quote'" class="content">
                <h2 class="title">Your Requested Quote is on Its Way!</h2>
                  <h4 class="subtitle">
                    Great news!
                  </h4>
                  <h4 class="subtitle">The quote you requested is now in the works and will soon land in your email inbox.</h4>
                    <h4 class="subtitle">Don't forget to peek into your spam folder, just in case.</h4>
                    <h4 class="subtitle">
                      If you have any questions or need a helping hand, don't hesitate to reach out to us at <a href="mailto:contactus@gocoderz.com.">contactus@gocoderz.com</a>.</h4>
                    <h4>We're always here to assist you.</h4>
              </div>
          </div>
      </div>
      <ul class="shape-group">
          <li class="shape-1">
              <img src="/images/others/map-shape-2.png" alt="Shape">
          </li>
      </ul>
  </section>
  </div>
</template>

<script>
export default {
  name: 'SuccessView',
  components: {
  },
  computed: {
    successType () {
      const { query } = this.$route
      if (!query || !query.type) {
        return 'paid'
      }

      return query.type
    }
  }
}
</script>

<style lang="scss">
.success-page {
  .title, .subtitle {
    margin-bottom: 32px !important;
  }

  .title {
    margin-top: 32px !important;
  }

  .subtitle {
    a {
      color: #f72887;
      text-decoration: underline;

      &:hover {
        color: #ff5b5c;
      }
    }
  }
}
</style>
