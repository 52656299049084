<template>
  <tr>
      <td class="product-remove">
          <a @click.prevent="removeFromCart" href="#" class="remove-wishlist"><i class="icon-73"></i></a>
      </td>
      <td class="product-thumbnail">
              <img :src="courseImage"
                  :alt="(productCourse.product.title) + ' Thumbnail'" />
      </td>
      <td class="product-title">
          {{ productCourse.product.name }}
      </td>
      <td class="product-seats" data-title="Seats">
          {{ productCourse.seats }}
      </td>
      <td class="product-school-year" data-title="Years">
        <template v-if="showSchoolYears">
          <Multiselect v-model="$v.courseSchoolYear.$model" :class="{'is-invalid': $v.courseSchoolYear.$error}" :multiple="true" :show-labels="false" label="name" track-by="value" :options="schoolYearOptions" placeholder="Select school year" />
          <div class="invalid-feedback">
            Please select school year
          </div>
        </template>
      </td>
      <td class="product-price" data-title="Price" v-html="priceHTML">
      </td>
      <td class="product-quantity" data-title="Qty">
          <div class="edu-quantity-btn edublink-vue-product-btn">
              <div class="pro-qty">
                  <button :disabled="!canDecrease" @click="decreaseQuantity" class="dec qtybtn">-</button>
                  <input type="text" :value="product.quantity" readonly>
                  <button @click="increaseQuantity" class="inc qtybtn">+</button>
              </div>
          </div>
      </td>
      <td class="product-subtotal" data-title="Subtotal" v-html="courseTotal"></td>
  </tr>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { DomainName } from 'coderz-utils/index-no-deps'
import { required, minLength } from 'vuelidate/lib/validators'
const IMAGES_ROOT = `${window.location.protocol}//activities.${DomainName}`
const S3_IMAGES_ROOT = `${window.location.protocol}//images.${DomainName}/store`

export default {
  props: {
    product: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    Multiselect
  },
  validations () {
    return {
      courseSchoolYear: {
        required,
        minLength: minLength(1)
      }
    }
  },
  computed: {
    schoolYearOptions () {
      const currentDate = new Date()
      const currentYear = currentDate.getFullYear()
      const currentMonth = currentDate.getMonth() + 1

      const startYear = currentMonth < 8 ? currentYear - 1 : currentYear

      return [{
        name: `Aug ${startYear}`,
        value: 0
      },
      {
        name: `Aug ${startYear + 1}`,
        value: 1
      }
      ]
    },
    showSchoolYears () {
      return this.productCourse.license_model === 'x-month'
    },
    productCourse () {
      return this.$store.state.courses.coursesList.find((course) => course.sku === this.product.sku)
    },
    courseImage () {
      if (this.product.metadata && this.product.metadata.image_url) {
        return `${S3_IMAGES_ROOT}/${this.course.metadata.image_url}`
      }
      return `${IMAGES_ROOT}/assets/images/${this.productCourse.product.image}`
    },
    canDecrease () {
      return this.product.quantity > 1
    },
    courseTotal () {
      const { total } = this.product
      const { currency } = this.productCourse

      if (total === 0) {
        return this.$t('Free')
      }

      switch (currency) {
        case 'USD':
          return `<span class="currency-symbol">$</span>${total}`
        default:
          return `${total}`
      }
    },
    priceHTML () {
      const { price } = this.product
      const { currency } = this.productCourse
      if (price === 0) {
        return this.$t('Free')
      }

      switch (currency) {
        case 'USD':
          return `<span class="currency-symbol">$</span>${price}`
        default:
          return `${price}`
      }
    },
    courseSchoolYear: {
      get () {
        return this.product.schoolYear.map((sy) => this.schoolYearOptions.find((syo) => syo.value === sy))
      },
      set (value) {
        this.$store.dispatch('cart/updateSchoolYear', {
          product: this.product,
          value,
          isValid: !!value.length
        })
      }
    }
  },
  methods: {
    increaseQuantity () {
      this.$store.dispatch('cart/increaseQuantity', this.product)
    },
    decreaseQuantity () {
      this.$store.dispatch('cart/decreaseQuantity', this.product)
    },
    removeFromCart () {
      this.$store.dispatch('cart/removeFromCart', this.product)
    }
  }
}
</script>
