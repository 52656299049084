<template>
  <div id="app">
    <HeaderOne :show-cart="showCart"/>
    <router-view/>
    <BottomPanel />
    <AppLoader v-if="isAppLoading" />
  </div>
</template>

<script>
import HeaderOne from '@/components/header/HeaderOne.vue'
import AppLoader from '@/components/AppLoader.vue'
import BottomPanel from '@/components/BottomPanel.vue'

import 'bootstrap/scss/bootstrap.scss'
import '@/assets/scss/app.scss'
import 'vue-multiselect/dist/vue-multiselect.min.css'
export default {
  name: 'App',
  components: {
    HeaderOne,
    AppLoader,
    BottomPanel
  },
  computed: {
    showCart () {
      const disallowedRoutes = ['not-available', 'login', 'success']
      return !disallowedRoutes.includes(this.$route.name)
    },
    isAppLoading () {
      return this.$store.state.isAppLoading
    }
  }
}
</script>

<style lang="scss">
.edu-btn {
  &--disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.edu-breadcrumb-area {
  padding-bottom: 16px;
  padding-top: 32px;
}

.edu-section-gap {
  padding-top: 16px;
}

.section-gap-equal {
  padding-top: 16px;
  padding-bottom: 32px;
}
</style>
