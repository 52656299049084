import Vue from 'vue'
import UserApi from '@/api/user'
import GeoApi from '@/api/geo'

import { Auth, utils } from 'coderz-utils/index-no-deps'

const DEFAULT_STATE = {
  token: Auth.getToken(),
  userInfo: {},
  images: [],
  hasReportsAccess: false,
  hasAdminAccess: false,
  hasHelpAccess: false,
  hasSubscriptionAccess: false,
  hasManagerAccess: false,
  hasContentAccess: false,
  subscriptionLink: null,
  currentApp: null,
  loggedInData: {},
  userSchools: [],
  isLogined: false,
  schoolFormData: {
    selectedSchool: null,
    selectedSchoolUS: null,
    schoolCountry: 'US',
    schoolNCES: '',
    schoolState: null,
    schoolName: null,
    schoolDistrict: null
  },
  userFormData: {
    firstName: '',
    lastName: '',
    email: ''
  },
  isUserSet: false,
  loginFormData: {
    countryCode: '',
    phoneNumber: '',
    validationCode: ''
  },
  countriesList: [],
  hasPendingValidation: false
}

const mutations = {
  SET_TOKEN (state, token) {
    state.token = token
  },
  SET_COUNTRIES_LIST (state, payload) {
    state.countriesList = payload
  },
  SET_USER_COUNTRY (state, payload) {
    if (payload && payload.country && payload.country !== 'XX') {
      state.userCountry = payload.country
    }
  },
  SET_USER_INFO (state, { payload, transformKeys = false }) {
    Object.keys(payload).forEach((key) => {
      let keyToSet = key
      if (transformKeys) {
        keyToSet = utils.camelToSnakeCase(key)
      }
      Vue.set(state.userInfo, keyToSet, payload[key])
    })
  },
  SET_LOGGED_IN_DATA (state, payload) {
    state.loggedInData = payload
  },
  SET_USER_SCHOOLS (state, payload) {
    state.userSchools = payload
  },
  SET_IS_LOGINED (state, payload) {
    state.isLogined = payload
  },
  SET_USER_FORM_VALUE (state, { key, value }) {
    state.userFormData[key] = value
  },
  SET_SCHOOL_FORM_VALUE (state, { key, value }) {
    state.schoolFormData[key] = value
  },
  SET_LOGIN_FORM_VALUE (state, { key, value }) {
    state.loginFormData[key] = value
  },
  SET_HAS_PENDING_VALIDATION (state, payload) {
    state.hasPendingValidation = payload
  },
  SET_IS_USER_SET (state, payload) {
    state.isUserSet = payload
  }
}

const actions = {
  // user login
  login ({ commit }, userInfo) {
    const { username, password } = userInfo
    return new Promise((resolve, reject) => {
      UserApi.login({ email: username.trim(), password })
        .then((response) => {
          const { data } = response
          commit('SET_TOKEN', data.token)
          Auth.setToken(data.token)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  async isLogin ({ commit }) {
    try {
      const { data } = await UserApi.isLogin()
      commit('SET_IS_LOGINED', data)
      return data
    } catch (e) {
      return false
    }
  },

  // get user info
  async getInfo ({ commit, state, dispatch }) {
    const infoResponse = await UserApi.getInfo()
    const { data } = infoResponse
    commit('SET_USER_INFO', { payload: data })
  },

  // user logout
  logout ({ commit, state, dispatch }) {
    return new Promise((resolve, reject) => {
      UserApi.logout()
        .then(() => {
          Auth.removeToken()
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // remove token
  resetToken ({ commit }) {
    return new Promise((resolve) => {
      commit('SET_TOKEN', '')
      commit('SET_ROLES', [])
      Auth.removeToken()
      resolve()
    })
  },

  async getCountriesList ({ commit }) {
    const result = await GeoApi.getCountries()
    commit('SET_COUNTRIES_LIST', result.data || [])
    return result
  },

  async getUserCountry ({ commit }) {
    const result = await GeoApi.getCountryAndState()
    commit('SET_USER_COUNTRY', result.data)
  },

  async getUserSchools ({ commit }) {
    const result = await UserApi.getUserSchools()
    commit('SET_USER_SCHOOLS', result)
  },

  async sendValidationCode ({ commit }, { dialCode, phoneNumber }) {
    try {
      const res = await UserApi.sendValidationCode(dialCode, phoneNumber)
      commit('SET_HAS_PENDING_VALIDATION', true)
      return res
    } catch (e) {
      return false
    }
  },

  async verifyValidationCode ({ commit }, { code }) {
    try {
      const res = await UserApi.verifyValidationCode(code)
      return res
    } catch (e) {
      return false
    }
  },

  async checkPendingValidation ({ commit }) {
    try {
      const res = await UserApi.hasPendingValidation()
      commit('SET_HAS_PENDING_VALIDATION', res)
      return res
    } catch (e) {
      return false
    }
  },

  async dropPendingValidation ({ commit }) {
    try {
      commit('SET_HAS_PENDING_VALIDATION', false)
      return true
    } catch (e) {
      return false
    }
  },

  async isCountryAuthenticated () {
    const res = await UserApi.isCountryAuthenticated()
    return res
  },

  async isCountryAllowed () {
    try {
      const res = await UserApi.isCountryAllowed()
      return res
    } catch (e) {
      return false
    }
  },

  async getUser ({ commit }) {
    const result = await UserApi.getUser()
    if (result) {
      const { email, first_name: firstName, last_name: lastName } = result
      commit('SET_USER_FORM_VALUE', { key: 'email', value: email })
      commit('SET_USER_FORM_VALUE', { key: 'firstName', value: firstName })
      commit('SET_USER_FORM_VALUE', { key: 'lastName', value: lastName })
      commit('user/SET_IS_USER_SET', true, { root: true })
    }
  },

  async setUser ({ commit }, { firstName, lastName, email }) {
    const res = {
      state: 'PENDING',
      result: null
    }
    try {
      res.state = 'SUCCESS'
      res.result = await UserApi.setUser({ firstName, lastName, email })
      if (res) {
        commit('SET_IS_USER_SET', res.result)
      }
    } catch (e) {
      res.state = 'ERROR'
      res.error = e
      commit('SET_IS_USER_SET', false)
    }

    return res
  }
}

export default {
  namespaced: true,
  state: DEFAULT_STATE,
  mutations,
  actions
}
