import Vue from 'vue'
import VueRouter from 'vue-router'
import MainView from '@/views/MainView.vue'
import CartView from '@/views/CartView.vue'
import LoginView from '@/views/LoginView.vue'
import CheckoutView from '@/views/CheckoutView.vue'
import NotAvailableView from '@/views/NotAvailableView.vue'
import SuccessView from '@/views/SuccessView.vue'
import FailView from '@/views/FailView.vue'

import store from '@/store'

Vue.use(VueRouter)

const checkIsAvailable = async (to, from, next) => {
  store.commit('SET_APP_LOADING', true)
  await store.dispatch('user/isLogin')
  const coursesResult = await store.dispatch('courses/getCoursesList')
  if (coursesResult) {
    await store.dispatch('cart/getCart')
    store.commit('SET_APP_LOADING', false)
    next()
  } else {
    store.commit('SET_APP_LOADING', false)
    const coursesError = store.state.courses.coursesError
    if (coursesError.code === 4206) {
      next('/login')
    } else if (coursesError.code === 4207) {
      next('/not-available')
    }
  }
}

const routes = [
  {
    path: '/',
    name: 'main',
    component: MainView,
    beforeEnter: checkIsAvailable
  },
  {
    path: '/cart',
    name: 'cart',
    component: CartView,
    beforeEnter: checkIsAvailable
  },
  {
    path: '/not-available',
    name: 'not-available',
    component: NotAvailableView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    beforeEnter: async (to, from, next) => {
      store.commit('SET_APP_LOADING', true)
      await store.dispatch('user/isLogin')
      await store.dispatch('user/getCountriesList')
      await store.dispatch('user/checkPendingValidation')
      store.commit('SET_APP_LOADING', false)
      next()
    }
  },
  {
    path: '/checkout',
    name: 'checkout',
    component: CheckoutView,
    beforeEnter: async (to, from, next) => {
      store.commit('SET_APP_LOADING', true)
      const isLogined = await store.dispatch('user/isLogin')
      try {
        await store.dispatch('user/getUser')
      } catch (e) {
      }

      if (isLogined) {
        await store.dispatch('user/getUserSchools')
      }

      const coursesResult = await store.dispatch('courses/getCoursesList')
      if (coursesResult) {
        await store.dispatch('cart/getCart')
        store.commit('SET_APP_LOADING', false)
        next()
      } else {
        const coursesError = store.state.courses.coursesError
        store.commit('SET_APP_LOADING', false)
        if (coursesError.code === 4206) {
          next('/login')
        } else if (coursesError.code === 4207) {
          next('/not-available')
        }
      }
    }
  },
  {
    path: '/success',
    name: 'success',
    component: SuccessView,
    beforeEnter: async (to, from, next) => {
      store.commit('SET_APP_LOADING', true)
      await store.dispatch('user/isLogin')
      const coursesResult = await store.dispatch('courses/getCoursesList')
      store.commit('SET_APP_LOADING', false)
      if (coursesResult) {
        next()
      } else {
        const coursesError = store.state.courses.coursesError
        if (coursesError.code === 4206) {
          next('/login')
        } else if (coursesError.code === 4207) {
          next('/not-available')
        }
      }
    }
  },
  {
    path: '/fail',
    name: 'fail',
    component: FailView,
    beforeEnter: async (to, from, next) => {
      store.commit('SET_APP_LOADING', true)
      await store.dispatch('user/isLogin')
      const coursesResult = await store.dispatch('courses/getCoursesList')
      store.commit('SET_APP_LOADING', false)
      if (coursesResult) {
        next()
      } else {
        const coursesError = store.state.courses.coursesError
        if (coursesError.code === 4206) {
          next('/login')
        } else if (coursesError.code === 4207) {
          next('/not-available')
        }
      }
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
