import Cookies from 'js-cookie'

class Cookie {
  static setCookie (name, value, exp) {
    // eslint-disable-next-line no-param-reassign
    if (!exp) exp = new Date(new Date().getTime() + 60 * 60 * 1000) // One hour

    Cookies.set(name, value, { expires: exp })
  }

  static getCookie (name) {
    return Cookies.get(name)
  }

  static removeCookie (name, domain = null) {
    const cookieOptions = domain
      ? {
          domain
        }
      : {}
    return Cookies.remove(name, cookieOptions)
  }
}

export default Cookie
