<template>
  <div id="main-wrapper" class="main-wrapper not-available-page">
    <section class="section-gap-equal error-page-area">
      <div class="container">
          <div class="edu-error">
              <div class="content">
                  <h2 class="title">Hey! Thank you for visiting CoderZ,</h2>
                  <h4 class="subtitle">While our online store is currently only available to our USA clients, we remain committed to bringing educational technology to amazing educators all over the globe.
To purchase our world-class courses and curriculum, please contact us at <a href="mailto:support@gocoderz.com">support@gocoderz.com</a>. Our dedicated team is there to support your journey and make it an incredible one. You're just an email away from fulfilling your educational dreams! 🚀🌍</h4>
              </div>
          </div>
      </div>
      <ul class="shape-group">
          <li class="shape-1">
              <img src="/images/others/map-shape-2.png" alt="Shape">
          </li>
      </ul>
  </section>
  </div>
</template>

<script>
export default {
  name: 'MainView',
  components: {
  }
}
</script>

<style lang="scss">
.not-available-page {
  .title, .subtitle {
    margin-bottom: 32px !important;
  }

  .title {
    margin-top: 32px !important;
  }

  .subtitle {
    a {
      color: #f72887;
      text-decoration: underline;

      &:hover {
        color: #ff5b5c;
      }
    }
  }
}
</style>
