<template>
    <header class="edu-header header-style-1 header-fullwidth no-topbar">
        <HeaderTopOne v-if="showHeaderTop" />
        <div class="header-mainmenu" :class="{ 'edu-sticky': isSticky }">
            <div class="container-fluid">
                <div class="header-navbar">
                    <div class="header-brand">
                        <div class="logo">
                            <RouterLink to="/">
                                <img class="logo-light" src="/images/Logo.svg" alt="Dark Logo">
                                <img class="logo-dark" src="/images/Logo.svg" alt="Light Logo">
                            </RouterLink>
                        </div>
                    </div>
                    <div class="header-mainnav">
                        <nav class="mainmenu-nav">
                        </nav>
                    </div>
                    <div class="header-right">
                        <ul v-if="showCart || isUserLogined" class="header-action">
                            <li v-if="showCart" class="icon cart-icon">
                                <RouterLink to="/cart" class="cart-icon">
                                    <i class="icon-3"></i>
                                    <span class="count">{{ cartCount }}</span>
                                </RouterLink>
                            </li>
                            <div v-if="isUserLogined" class="profile-iframe-container">
                              <iframe ref="profileWidget" id="profileWidget" class="profile-iframe" :src="collapsedProfileUrl"></iframe>
                            </div>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <SearchPopUp />

        <OffCanvasMobileMenuOne />
    </header>
</template>

<script>
/* eslint-disable */
import CoderzNavigation from './CoderzNavigation.vue'
import CoderzCategory from './CoderzCategory.vue'
import HeaderTopOne from './HeaderTopOne.vue'
import OffCanvasMobileMenuOne from './OffCanvasMobileMenuOne.vue'
import SearchPopUp from './SearchPopUp.vue'

export default {
    components: {
    CoderzNavigation,
    CoderzCategory,
    HeaderTopOne,
    OffCanvasMobileMenuOne,
    SearchPopUp
},
    data() {
        return {
            isSticky: false
        }
    },
    props: ['showHeaderTop', 'showCart'],
    mounted() {
        /* window.addEventListener('scroll', () => {
            let scrollPos = window.scrollY
            if (scrollPos >= 200) {
                this.isSticky = true
            } else {
                this.isSticky = false
            }
        }) */
    },
    methods: {
        // Off-canvas Mobile Menu Open
        mobileMenuOpen(addRemoveClass, className) {
            const el = document.querySelector('#offcanvas-menu');
            if (addRemoveClass === 'addClass') {
                el.classList.add(className);
            } else {
                el.classList.remove(className);
            }
        },

        // search popup
        searchPopUpOpen(addRemoveClass, className) {
            const el = document.querySelector('#edu-search-popup');
            if (addRemoveClass === 'addClass') {
                el.classList.add(className);
            } else {
                el.classList.remove(className);
            }
        }
    },
    computed: {
      cartCount () {
        return this.$store.state.cart.userCart.length
      },
      isUserLogined () {
        return this.$store.state.user.isLogined
      },
      collapsedProfileUrl () {
        const domain = window.location.hostname.split('.')
        domain.shift()

        return location.protocol + "//account." + domain.join('.') + "/profile/collapsed"
      }
    }
}
</script>

<style lang="scss">
.profile-iframe {
  border: 0;
  height: 30px;
}

.profile-iframe-container {
  display: flex;
  justify-content: center;
  max-width: 128px;
  width: 128px;
  margin-right: 16px;
}

.cdz-profile-container {
  top: 90px !important;
}

.icon.cart-icon {
  margin-right: 16px !important;
}

.cart-icon {
  height: 30px !important;
  display: flex !important;
  align-items: center !important;

  .count {
    top: -5px !important;
    line-height: 20px !important;
  }
}
</style>
