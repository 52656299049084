const domain = window.location.hostname.split(".");
domain.shift();

const DomainName = domain.join(".");

export default DomainName;

const domainForSubdomain = window.location.hostname.split(".");
domainForSubdomain.shift();
domainForSubdomain.shift();

export const DomainForSubdomainName = domainForSubdomain.join(".");
