import { request } from 'coderz-utils/index-no-deps'

class User {
  static login (data, reCAPTCHAToken) {
    return request({
      url: '@api/auth/login',
      data: { ...data, 'g-recaptcha-response': reCAPTCHAToken }
    })
  }

  static ssoLogin (token) {
    return request({
      url: '/auth/coderZSSOHandler',
      data: {
        token
      }
    })
  }

  static getInfo () {
    return request({ url: '@api/auth/profile' })
  }

  static logout () {
    return request({ url: '@api/auth/logout' })
  }

  static isLogin () {
    return request({ url: '@api/auth/is-logged-in' })
  }

  static getImages () {
    return request({ url: '@api/user/get-images' })
  }

  static update (data) {
    return request({ url: '@api/user/update/my-profile', data })
  }

  static checkReportsAccess () {
    return request({ url: '@api/user/reports-license' })
  }

  static checkAdminAccess () {
    return request({ url: '@api/app/license/has-access' })
  }

  static checkContentAccess () {
    return request({ url: '@api/app/content/has-access' })
  }

  static checkManagerAccess () {
    return request({ url: '@api/app/manager/has-access' })
  }

  static checkHelpAccess () {
    return request({ url: '@api/app/help/has-access' })
  }

  static checkSubscriptionAccess () {
    return request({ url: '@api/app/subscription/has-access' })
  }

  static validateResetPassToken (token) {
    return request({
      url: '@api/user/validate-reset-password-token',
      data: {
        token
      }
    })
  }

  static resetPassEmail (userEmail, reCAPTCHAToken) {
    return request({
      url: '@api/user/forgot-password',
      data: {
        email: userEmail,
        'g-recaptcha-response': reCAPTCHAToken
      }
    })
  }

  static setNewPassword (token, password, reCAPTCHAToken) {
    return request({
      url: '@api/user/reset-my-password',
      data: {
        token,
        password,
        'g-recaptcha-response': reCAPTCHAToken
      }
    })
  }

  static createStudent (data, reCAPTCHAToken) {
    return request({
      url: '@api/user/create-student',
      data: {
        ...data,
        'g-recaptcha-response': reCAPTCHAToken
      }
    })
  }

  static checkUsername (username, reCAPTCHAToken) {
    return request({
      url: '@api/user/check-username-availability',
      data: {
        username,
        'g-recaptcha-response': reCAPTCHAToken
      }
    })
  }

  static confirmEmail (token) {
    return request({
      url: '@api/user/confirm-via-token',
      data: {
        token
      }
    })
  }

  static twoFactorGetQR (token) {
    return request({
      url: '@api/auth/login/2fa-register-qr',
      data: {
        token
      }
    })
  }

  static twoFactorSendPhone ({ phoneNumber, token }) {
    return request({
      url: '@api/auth/login/2fa-register-phone',
      data: {
        token,
        phoneNumber
      }
    })
  }

  static twoFactorSendSMSCode ({ code, token, remember }) {
    return request({
      url: '@api/auth/login/2fa-verify-sms',
      data: {
        token,
        code,
        remember
      }
    })
  }

  static twoFactorSendAuthenticatorCode ({ code, token, remember }) {
    return request({
      url: '@api/auth/login/2fa-verify-qr',
      data: {
        token,
        code,
        remember
      }
    })
  }

  static lostTFA ({ token }) {
    return request({
      url: '@api/user/reset-authenticator',
      data: {
        token
      }
    })
  }

  static confirmTfaReset ({ token }) {
    return request({
      url: '@api/user/confirm/reset-authenticator',
      data: {
        token
      }
    })
  }

  // TBD
  static postResendEmail (payload) {
    return request({
      url: '@api/user/resendEmail',
      data: payload
    })
  }

  // TBD
  static postCancelInvitation (payload) {
    return request({
      url: '@api/user/cancelInvitation',
      data: payload
    })
  }

  static checkNicknameAvailability (nickname) {
    return request({
      url: '@api/user/check-nickname-availability',
      data: { nickname }
    })
  }

  static getUserSchools () {
    return request({
      url: '@api/school/my/all'
    })
  }

  static sendValidationCode (dialCode, phoneNumber) {
    return request({
      url: '@store/auth/send-validation-code',
      data: {
        dialCode,
        phoneNumber
      }
    })
  }

  static verifyValidationCode (code) {
    try {
      return request({
        url: '@store/auth/verify-validation-code',
        data: {
          code
        }
      })
    } catch (e) {
      throw new Error(e)
    }
  }

  static hasPendingValidation () {
    return request({
      url: '@store/auth/has-pending-validation'
    })
  }

  static dropPendingValidation () {
    return request({
      url: '@store/auth/drop-pending-validation'
    })
  }

  static isCountryAuthenticated () {
    return request({
      url: '@store/auth/is-country-authenticated'
    })
  }

  static isCountryAllowed () {
    return request({
      url: '@store/auth/is-country-allowed'
    })
  }

  static setUser ({ firstName, lastName, email }) {
    return request({
      url: '@store/auth/set-user',
      data: { firstName, lastName, email }
    })
  }

  static getUser () {
    return request({
      url: '@store/auth/get-user'
    })
  }
}

export default User
