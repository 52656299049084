import Vue from 'vue'
import VueI18n from 'vue-i18n'

import ElementLocale from 'element-ui/lib/locale/lang/en'

import Cookie from './cookie'
import DomainName, { DomainForSubdomainName } from './domain'

const LANGUAGES_URL = `${window.location.protocol}//languages.${DomainName}`
const LANGUAGES_SUBDOMAIN_URL = `${window.location.protocol}//languages.${DomainForSubdomainName}`

export const TranslateEventBus = new Vue()

const DEFAULT_LANGUAGE = 'en'

const messages = { en: {} }

Vue.use(VueI18n)

export const i18n = new VueI18n({
  locale: DEFAULT_LANGUAGE, // set locale
  fallbackLocale: DEFAULT_LANGUAGE,
  messages, // set locale messages
  silentTranslationWarn: true // don't show warnings about missing translation
})

const loadedLanguages = new Set()

const languages = new Map()

let langsConfig = null

const loadTranslations = async (languageName, version, useSubdomain = false) => {
  const base = useSubdomain ? LANGUAGES_SUBDOMAIN_URL : LANGUAGES_URL
  const responses = await Promise.all([
    fetch(
      `${base}/site/${languageName}.json?${version}`
    ),
    fetch(
  `${base}/missions/${languageName}.json?${version}`
    ),
    fetch(
  `${base}/account/${languageName}.json?${version}`
    )
  ])
  const results = []
  for (const response of responses) {
    results.push(response.json())
  }
  const siteData = {
    config: {},
    translations: {}
  };

  (await Promise.all(results)).forEach(data => {
    if (data.config) {
      siteData.config = data.config
    }
    Object.assign(siteData.translations, data.translation || data.translations || data)
  })

  // eslint-disable-next-line no-restricted-syntax

  return siteData
}

const getParameterByName = (name) => {
  const url = window.location.href
  // eslint-disable-next-line no-param-reassign
  name = name.replace(/[[\]]/g, '\\$&')
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`)
  const results = regex.exec(url)
  if (!results) return ''
  if (!results[2]) return ''
  const result = decodeURIComponent(results[2]).split('&')
  return result[0].replace(/\+/g, ' ')
}

const getOSlang = () => {
  const osLang = (navigator.language || navigator.userLanguage)
    .toLowerCase()
    .split('-')[0]
  if (languages.has(osLang)) return osLang
  return DEFAULT_LANGUAGE
}

const getLanguage = () => {
  let lang = getParameterByName('lang')
  if (!lang) {
    lang = Cookie.getCookie('lang')
    if (!lang) lang = getOSlang()
  }
  lang = lang.toLowerCase()
  return lang || DEFAULT_LANGUAGE
}

const getDirection = (lang) => {
  if (!languages.has(lang)) return 'ltr'

  return languages.get(lang).direction
}

const setI18nLanguage = (lang) => {
  i18n.locale = lang

  // axios.defaults.headers.common["Accept-Language"] = lang;

  document.querySelector('html').setAttribute('lang', lang)

  const dir = getDirection(lang)

  document.querySelector('html').setAttribute('dir', dir)

  return lang
}

const addCookie = (name, value, expirationDays, path, domain) => {
  let date = new Date()
  date.setTime(+date + expirationDays * 86400000)
  date = date.toGMTString()
  document.cookie = `${name}=${value}; expires=${date}; path=${path}; domain=${domain}`
}

const getLanguagesAsync = async (languagesToLoad = [DEFAULT_LANGUAGE], useSubdomain = false) => {
  const timeNow = new Date().getTime()

  const configResponse = await fetch(
        `${useSubdomain ? LANGUAGES_SUBDOMAIN_URL : LANGUAGES_URL}/config/all.json?${timeNow}`
  )
  const configData = await configResponse.json()

  const promises = []

  const currentLanguages = [...new Set(languagesToLoad)]

  langsConfig = {
    ...configData,
    languages: configData.languages.filter(({ supported }) => !!supported)
  }

  configData.languages.forEach((lang) => {
    if (!lang.supported || !currentLanguages.includes(lang.code)) return

    promises.push(
      new Promise(async (resolve) => {
        const langData = await loadTranslations(
          lang.code,
          configData.version,
          useSubdomain
        )
        // TODO DIMA: Restore this line after finishing updating local translations
        // const langData = await langResponse.json();

        const langConfig = langData.config
        const langTranslations = langData.translations

        langConfig.code = lang.code

        i18n.setLocaleMessage(lang.code, langTranslations)
        loadedLanguages.add(lang.code)

        languages.set(lang.code, langConfig)

        resolve()
      })
    )
  })

  await Promise.all(promises)
}

export const getLanguages = () => {
  const returnData = langsConfig.languages.map((lang) => ({
    label: lang.name,
    value: lang.code
  }))

  return returnData
}

export const getLanguageByCode = (lang) => {
  if (languages.has(lang)) return languages.get(lang)
  return languages.get(DEFAULT_LANGUAGE)
}

const haveLangInConfig = (lang) => languages.has(lang)

export const loadLanguageAsync = async (useSubdomain = false) => {
  const lang = getLanguage()

  if (!languages.size || !haveLangInConfig(lang)) {
    await getLanguagesAsync([DEFAULT_LANGUAGE, lang], useSubdomain)
  }

  // If the same language
  if (i18n.locale === lang) return Promise.resolve(setI18nLanguage(lang))

  // If the language was already loaded
  if (loadedLanguages.has(lang)) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  const configResponse = await fetch(
        `${useSubdomain ? LANGUAGES_SUBDOMAIN_URL : LANGUAGES_URL}/config/all.json?${new Date().getTime()}`
  )
  const configData = await configResponse.json()

  const languageData = await loadTranslations(lang, configData.version, useSubdomain)

  i18n.setLocaleMessage(lang, languageData.translations)
  loadedLanguages.add(lang)
  return setI18nLanguage(lang)
}

export const setLanguage = async (lang, useSubdomain = false) => {
  // eslint-disable-next-line no-param-reassign
  if (!lang) lang = getOSlang()
  const mainDomainSpl = window.location.hostname.split('.')
  if (useSubdomain) {
    const cookieDomain = `.${mainDomainSpl[mainDomainSpl.length - 2]}.${mainDomainSpl[mainDomainSpl.length - 1]}`
    Cookie.removeCookie('lang', window.location.hostname.replace(/^[a-z1-9]+/, ''))
    addCookie('lang', lang, 30, '/', cookieDomain)
  } else {
    addCookie('lang', lang, 30, '/', window.location.hostname.replace(/^[a-z1-9]+/, ''))
  }
  await loadLanguageAsync(useSubdomain)
  TranslateEventBus.$emit('language-set', lang)
  // return lang;
}

export { ElementLocale }
