import Cookies from "js-cookie";
import DomainName from "./domain";

const TokenKey = "sessionToken";

class Auth {
    static getToken() {
        return Cookies.get(TokenKey);
    }

    static setToken(token) {
        return Cookies.set(TokenKey, token, { domain: DomainName });
    }

    static removeToken() {
        return Cookies.remove(TokenKey, { domain: DomainName });
    }
}

export default Auth;
