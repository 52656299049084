<template>
  <div v-if="showPanel" class="coderz-store-bottom-panel">
    <template v-if="isMainPage">
      <div class="coderz-store-bottom-panel__spacer"></div>
      <RouterLink to="/cart" class="edu-btn btn-medium checkout-btn">Go to Cart<i
                            class="icon-4"></i></RouterLink>
    </template>
    <template v-else-if="isCartPage">
      <RouterLink to="/" class="edu-btn btn-medium checkout-btn btn-secondary"><i
                                  class="icon-west"></i>Back
                                  </RouterLink>
      <div class="coderz-store-bottom-panel__spacer"></div>
      <RouterLink to="/checkout" class="edu-btn btn-medium checkout-btn" :class="{'edu-btn--disabled': isCartLoading}">Proceed to Checkout <i
                                  class="icon-east"></i></RouterLink>
    </template>
    <template v-else-if="isCheckoutPage">
      <RouterLink to="/cart" class="edu-btn btn-medium checkout-btn btn-secondary"><i
                                  class="icon-west"></i>Back
                                  </RouterLink>
    </template>
  </div>
</template>

<script>
export default {
  computed: {
    showPanel () {
      if (this.isMainPage) {
        return this.userCart.length
      }

      if (this.isCartPage) {
        return this.userCart.length
      }

      if (this.isCheckoutPage) {
        return true
      }

      return false
    },
    isMainPage () {
      return this.$route.name === 'main'
    },
    isCartPage () {
      return this.$route.name === 'cart'
    },
    isCheckoutPage () {
      return this.$route.name === 'checkout'
    },
    userCart () {
      return this.$store.state.cart.userCart
    },
    isCartLoading () {
      return this.$store.state.cart.isCartLoading
    }
  }
}
</script>

<style lang="scss">
.coderz-store-bottom-panel {
  padding-left: 62px;
  padding-right: 62px;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 99;
  height: 64px;
  display: flex;
  align-items: center;

  pointer-events: none;

  .edu-btn {
    pointer-events: initial;
  }

  &__spacer {
    flex: 1;
  }
}

@media only screen and (max-width: 767px) {
  .coderz-store-bottom-panel {
    padding-left: 8px;
    padding-right: 8px;
  }
}
</style>
