import Vue from 'vue'
import VueGtag from 'vue-gtag'
import Vuelidate from 'vuelidate'

import App from './App.vue'
import router from './router'
import store from './store'

import CoderzPlugin, { translationUtils, routingUtils } from 'coderz-utils/index-no-deps'

Vue.config.productionTip = false

if (process.env.VUE_APP_GTAG_ID && process.env.VUE_APP_GTAG_ID.length) {
  Vue.use(VueGtag, {
    config: { id: process.env.VUE_APP_GTAG_ID }
  })
}

Vue.use(CoderzPlugin)
Vue.use(Vuelidate)

const domain = window.location.hostname.split('.')
domain.shift()

document.domain = domain.join('.')

const loadApp = async () => {
  try {
    await translationUtils.loadLanguageAsync()
    /* eslint-disable */
    new Vue({
      i18n: translationUtils.i18n,
      el: '#app',
      router,
      store,
      render: (h) => h(App)
    })
  } catch (e) {
    routingUtils.goBackToLogin(window.location.pathname + window.location.search)
  }
}

loadApp()
