<template>
    <div class="edu-course course-style-1 hover-button-bg-white" :class="extraClass">
        <div class="inner">
            <div class="thumbnail">
                <a>
                    <div class="course-image" :style="getCourseImageStyle"></div>
                </a>
                <div class="time-top">
                    <span class="duration">{{ priceString }}</span>
                </div>
            </div>
            <div class="content">
                <span v-if="courseLanguage" class="course-level">{{ courseLanguage }}</span>
                <h6 class="title">
                    <a>{{ courseProduct.name }}</a>
                </h6>
                <div class="course-price">{{  gradesString  }} / {{  course.seats  }} {{  $t('seats') }} / {{  monthString  }}</div>
                <ul class="course-meta">
                    <li><i class="icon-63"></i>{{ courseProduct.activity_count }} {{ courseProduct.activity_count > 1 ? 'Activities' : 'Activity' }}</li>
                    <li><i class="icon-61"></i>{{ hoursCount }} {{ hoursCount > 1 ? 'Hours' : 'Hour' }}</li>
                </ul>
                <div class="course-cart-button course-cart-button--mobile">
                  <a v-if="!isInCart" href="#" @click.prevent="addToCart" class="edu-btn btn-secondary btn-small">Add To Cart <i class="icon-3"></i></a>
                  <a v-else href="#" @click.prevent="removeFromCart" class="edu-btn btn-secondary btn-secondary--white btn-small">Remove From Cart <i class="icon-73"></i></a>
                </div>
            </div>
        </div>
        <!-- Products -> Cart -> Checkout (checkboxes for new users)-->
        <div class="course-hover-content-wrapper">
        </div>
        <div class="course-hover-content">
            <div class="content">
                <h6 class="title">
                  <a>{{ courseProduct.name }}</a>
                </h6>
                <div class="course-price">{{  priceString  }} / {{  course.seats  }} {{  $t('seats') }} / {{  monthString  }}</div>
                <p>{{ courseDescription }}</p>
                <div class="course-cart-button course-cart-button--desktop">
                  <a v-if="!isInCart" href="#" @click.prevent="addToCart" class="edu-btn btn-secondary btn-small">Add To Cart <i class="icon-3"></i></a>
                  <a v-else href="#" @click.prevent="removeFromCart" class="edu-btn btn-secondary btn-secondary--white btn-small">Remove From Cart <i class="icon-73"></i></a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { DomainName } from 'coderz-utils/index-no-deps'
const IMAGES_ROOT = `${window.location.protocol}//activities.${DomainName}`
const S3_IMAGES_ROOT = `${window.location.protocol}//images.${DomainName}/store`

const plurarize = (grade) => {
  if (grade === 1) {
    return `${grade}st`
  } else if (grade === 2) {
    return `${grade}nd`
  } else if (grade === 3) {
    return `${grade}rd`
  }

  return `${grade}th`
}

export default {
  props: ['extraClass', 'course'],
  computed: {
    courseProduct () {
      return this.course.product
    },
    courseDescription () {
      return this.course.metadata && this.course.metadata.description ? this.course.metadata.description : ''
    },
    courseLanguage () {
      return this.course.metadata && this.course.metadata.programming_language ? this.course.metadata.programming_language : null
    },
    courseImage () {
      if (this.course.metadata && this.course.metadata.image_url) {
        return `${S3_IMAGES_ROOT}/${this.course.metadata.image_url}`
      }

      return `${IMAGES_ROOT}/assets/images/${this.courseProduct.image}`
    },
    hoursCount () {
      return this.courseProduct.scope
    },
    gradesString () {
      const { min_grade: minGrade, max_grade: maxGrade } = this.courseProduct.metadata
      return `${plurarize(minGrade)}-${plurarize(maxGrade)} grades`
    },
    isInCart () {
      return this.$store.state.cart.userCart.find((c) => c.sku === this.course.sku)
    },
    priceString () {
      const { price, currency } = this.course
      if (price === 0) {
        return this.$t('Free')
      }

      switch (currency) {
        case 'USD':
          return `$${price}`
        default:
          return `${price}`
      }
    },
    getCourseImageStyle () {
      return {
        'background-image': `url(${this.courseImage})`
      }
    },
    monthString () {
      if (this.course.license_model === 'x-month' && this.course.duration_unit === 'month' && this.course.duration_amount === 12) {
        return this.$t('school year')
      }

      if (this.course.duration_unit === 'month') {
        return `${this.course.duration_amount} ${this.course.duration_amount === 1 ? this.$t('month') : this.$t('months')}`
      }

      return `${this.course.duration_amount} ${this.course.duration_unit}`
    }
  },
  methods: {
    addToCart () {
      this.$store.dispatch('cart/addToCart', this.course)
    },
    removeFromCart () {
      this.$store.dispatch('cart/removeFromCart', this.course)
    }
  }
}
</script>

<style lang="scss">
.course-image {
  height: 186px;
  width: 100%;
  background-size: cover;
  background-position: center center;
}

.course-cart-button {
  display: flex;
  justify-content: center;
  visibility: hidden;
}

@media only screen and (max-width: 767px) {
  .course-cart-button--mobile {
    visibility: visible;
  }
}

@media only screen and (min-width: 768px) {
  .course-cart-button--desktop {
    visibility: visible;
    margin-top: 16px;
  }
}

.dark-mode .edu-course .course-hover-content .content {
  .btn-secondary {
    &--white {
      color: #000 !important;
      background: #ffffff !important;

      &:hover {
        color: #ffffff !important;
        background: var(--color-secondary) !important;
      }
    }
  }
}

.dark-mode .edu-course .inner .content {
  .btn-secondary {
    &--white {
      color: #000 !important;
      background: #ffffff !important;

      &:hover {
        color: #ffffff !important;
        background: var(--color-secondary) !important;
      }
    }
  }
}
</style>
