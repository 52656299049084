<template>
  <div id="main-wrapper" class="main-wrapper">
    <div class="edu-breadcrumb-area">
        <div class="container">
            <div class="breadcrumb-inner">
                <div class="page-title">
                    <h1 class="title">{{ $t('Curriculum collection') }}</h1>
                    <h4>{{ $t('Select from our range of top-notch courses') }}</h4>
                </div>
            </div>
        </div>
    </div>
    <div id="courses-section" class="edu-course-area course-area-1 gap-tb-text gap-tb-text--courses">
            <div class="container">
                <div class="row g-5">
                    <div class="col-md-6 col-lg-4 col-xl-3" v-for="course in courses" :key="course.innerId">
                        <CoderzCourse :course="course" extraClass="course-box-shadow" />
                    </div>
                </div>
            </div>
        </div>
  </div>
</template>

<script>
import CoderzCourse from '@/components/course/CoderzCourse'

export default {
  name: 'MainView',
  components: {
    CoderzCourse
  },
  computed: {
    courses () {
      return this.$store.state.courses.coursesList
    },
    userCart () {
      return this.$store.state.cart.userCart
    }
  },
  data () {
    return {
    }
  }
}
</script>

<style lang="scss">
.row {
  &--go-to-cart {
    display: flex;
    justify-content: end;

    a {
      max-width: calc(25% - 30px);
    }

    padding-bottom: 16px;
  }
}

.gap-tb-text {
  &--courses {
    padding-top: 16px;
    padding-bottom: 64px;
  }
}
</style>
