<template>
  <div id="main-wrapper" class="main-wrapper">
    <div class="edu-breadcrumb-area">
        <div class="container">
            <div class="breadcrumb-inner">
                <div class="page-title">
                    <h1 class="title">Cart</h1>
                </div>
                <ul class="edu-breadcrumb">
                    <li class="breadcrumb-item">
                        <RouterLink to="/">Products</RouterLink>
                    </li>
                    <li class="separator"><i class="icon-angle-right"></i></li>
                    <li class="breadcrumb-item active" aria-current="page">Cart</li>
                </ul>
            </div>
        </div>
    </div>
      <section class="cart-page-area edu-section-gap">
          <div class="container">
              <div class="table-responsive">
                  <table class="table cart-table">
                      <thead>
                          <tr>
                              <th scope="col" class="product-remove"></th>
                              <th scope="col" class="product-thumbnail">Image</th>
                              <th scope="col" class="product-title">Product Name</th>
                              <th scope="col" class="product-seats">Seats</th>
                              <th scope="col" class="product-seats">School Year</th>
                              <th scope="col" class="product-price">Price</th>
                              <th scope="col" class="product-quantity">Quantity</th>
                              <th scope="col" class="product-subtotal">Subtotal</th>
                          </tr>
                      </thead>

                      <tbody>
                          <template v-if="userCart.length">
                            <CartRow v-for="product in userCart" :key="product.sku" :product="product"/>
                            <tr class="order-subtotal">
                                <td colspan="7">Subtotal</td>
                                <td>${{ cartTotal }}</td>
                            </tr>
                            <tr class="order-total">
                                <td colspan="7">Order Total</td>
                                <td>${{ cartTotal }}</td>
                            </tr>
                          </template>
                          <template v-else>
                            <tr>
                              <td colspan="6">Cart is empty</td>
                            </tr>
                          </template>
                      </tbody>
                  </table>
                  <div v-if="isCartLoading" class="table-loader">
                    <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                  </div>
              </div>
          </div>
      </section>
  </div>
</template>

<script>
import CartRow from '@/components/cart/CartRow'

import { DomainName } from 'coderz-utils/index-no-deps'
const IMAGES_ROOT = `${window.location.protocol}//activities.${DomainName}`

export default {
  components: {
    CartRow
  },
  head () {
    return {
      title: 'Cart Page'
    }
  },
  computed: {
    userCart () {
      return this.$store.state.cart.userCart
    },
    cartTotal () {
      return this.$store.state.cart.total
    },
    isCartLoading () {
      return this.$store.state.cart.isCartLoading
    }
  },
  methods: {
    courseImage (course) {
      return `${IMAGES_ROOT}/assets/images/${course.product.image}`
    },
    getQuantity (course) {
      return this.$store.state.cart.cartMeta[course.innerId].quantity
    },
    increaseQuantity (course) {
      this.$store.dispatch('cart/increaseQuantity', course)
    },
    decreaseQuantity (course) {
      this.$store.dispatch('cart/decreaseQuantity', course)
    },
    removeFromCart (course) {
      this.$store.dispatch('cart/removeFromCart', course)
    },
    canDecrease (course) {
      return this.$store.state.cart.cartMeta[course.innerId].quantity > 1
    },
    courseTotal (course) {
      return course.price * this.$store.state.cart.cartMeta[course.innerId].quantity
    },
    priceHTML (course) {
      const { price, currency } = course
      if (price === 0) {
        return this.$t('Free')
      }

      switch (currency) {
        case 'USD':
          return `<span class="currency-symbol">$</span>${price}`
        default:
          return `${price}`
      }
    }
  }
}
</script>

<style lang="scss">
.table-loader {
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .lds-roller {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-roller div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;
  }
  .lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #f72887;
    margin: -4px 0 0 -4px;
  }
  .lds-roller div:nth-child(1) {
    animation-delay: -0.036s;
  }
  .lds-roller div:nth-child(1):after {
    top: 63px;
    left: 63px;
  }
  .lds-roller div:nth-child(2) {
    animation-delay: -0.072s;
  }
  .lds-roller div:nth-child(2):after {
    top: 68px;
    left: 56px;
  }
  .lds-roller div:nth-child(3) {
    animation-delay: -0.108s;
  }
  .lds-roller div:nth-child(3):after {
    top: 71px;
    left: 48px;
  }
  .lds-roller div:nth-child(4) {
    animation-delay: -0.144s;
  }
  .lds-roller div:nth-child(4):after {
    top: 72px;
    left: 40px;
  }
  .lds-roller div:nth-child(5) {
    animation-delay: -0.18s;
  }
  .lds-roller div:nth-child(5):after {
    top: 71px;
    left: 32px;
  }
  .lds-roller div:nth-child(6) {
    animation-delay: -0.216s;
  }
  .lds-roller div:nth-child(6):after {
    top: 68px;
    left: 24px;
  }
  .lds-roller div:nth-child(7) {
    animation-delay: -0.252s;
  }
  .lds-roller div:nth-child(7):after {
    top: 63px;
    left: 17px;
  }
  .lds-roller div:nth-child(8) {
    animation-delay: -0.288s;
  }
  .lds-roller div:nth-child(8):after {
    top: 56px;
    left: 12px;
  }
}
.product-school-year {
  min-width: 242px !important;

  .multiselect__tags-wrap {
    display: flex;
    align-items: center;
    height: 100%;
  }
}

td.product-seats {
  text-align: center;
}

.cart-page-area {
  .table-responsive {
    border: 0.5px solid var(--color-white);
    border-radius: 16px;
    position: relative;

    tbody {
      td {
        border-bottom: 0.5px solid var(--color-white) !important;
      }

      tr:last-child {
        td {
          border-bottom: none !important;
        }
      }
    }
  }
}

.cart-page-area {

  .order-subtotal, .order-total {
  td:first-child {
    text-align: right;
    color: var(--color-white) !important;
    font-weight: var(--p-semi-bold);
  }
}

.cart-table {
  margin: 0;
}

.order-summery {
  margin-top: 32px;
}
}

</style>
