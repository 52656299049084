import DomainName, { DomainForSubdomainName } from './domain'

export const goBackToLogin = (toPath, license) => {
    if (license)
        window.location.href = `${window.location.protocol}//account.${DomainName}/license`
    else
        window.location.href = `${window.location.protocol}//account.${DomainName}?redirect_url=${window.location.origin}${toPath}`
}

export const redirect = (redirectUrl, useSubdomain = false) => {
    if (redirectUrl) window.location.href = redirectUrl
    else window.location.href = `${window.location.protocol}//play.${useSubdomain ? DomainForSubdomainName : DomainName}/dashboard/#/missions///`
}