import Vue from 'vue'
import Vuex from 'vuex'

import createPersistedState from 'vuex-persistedstate'

const modulesFiles = require.context('./modules', true, /\.js$/)

const dataState = createPersistedState({
  paths: []
})

const modules = modulesFiles.keys().reduce((mdls, modulePath) => {
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
  const value = modulesFiles(modulePath)
  mdls[moduleName] = value.default
  return mdls
}, {})

Vue.use(Vuex)

export default new Vuex.Store({
  modules,
  state: {
    isAppLoading: false
  },
  mutations: {
    SET_APP_LOADING (state, payload) {
      state.isAppLoading = payload
    }
  },
  plugins: [dataState]
})
