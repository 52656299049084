import CoursesApi from '@/api/courses'

const DEFAULT_STATE = {
  coursesList: [],
  priceList: [],
  isCoursesListLoading: false,
  coursesError: null
}

const mutations = {
  SET_COURSES_LIST (state, payload) {
    state.priceList = payload

    const coursesMap = state.priceList.reduce((acc, curr) => {
      if (!acc[curr.product_id]) {
        acc[curr.product_id] = []
      }

      acc[curr.product_id].push(curr)
      return acc
    }, {})

    Object.keys(coursesMap).forEach((key) => {
      coursesMap[key] = [...coursesMap[key]].sort((a, b) => a.price - b.price)
    })

    const coursesOrder = Object.keys(coursesMap).sort((a, b) => {
      const productA = coursesMap[a][0].product
      const productB = coursesMap[b][0].product
      return productA.order - productB.order
    })

    state.coursesList = coursesOrder.reduce((acc, curr) => {
      acc = [...acc, ...coursesMap[curr]]
      return acc
    }, []).sort((a, b) => {
      if (a.price !== b.price) {
        return a.price - b.price
      }

      return a.product.order - b.product.order
    })
  },
  SET_COURSES_LOADING (state, payload) {
    state.isCoursesListLoading = payload
  },
  SET_COURSES_ERROR (state, payload) {
    state.coursesError = payload
  }
}

const actions = {
  async getCoursesList ({ commit }) {
    let data = false
    commit('SET_COURSES_ERROR', null)
    commit('SET_COURSES_LOADING', true)
    try {
      data = await CoursesApi.getCoursesList()
      commit('SET_COURSES_LIST', data)
    } catch (e) {
      commit('SET_COURSES_ERROR', e)
      commit('SET_COURSES_LIST', [])
    } finally {
      commit('SET_COURSES_LOADING', false)
    }
    return data
  }
}

export default {
  namespaced: true,
  state: DEFAULT_STATE,
  mutations,
  actions
}
