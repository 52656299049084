<template>
  <div id="main-wrapper" class="main-wrapper fail-page">
    <section class="section-gap-equal error-page-area">
      <div class="container">
          <div class="edu-error">
            <div v-if="failType === 'payment'">
              <h2 class="title">Payment Unsuccessful</h2>
                  <h4 class="subtitle">
                    Unfortunately, we were unable to complete the transaction.</h4>
                  <h4 class="subtitle">
                    To ensure uninterrupted access to our services or products, please take a moment to review and update your payment information.</h4>
                    <h4 class="subtitle">You can do this by logging into your account on our website and navigating to the "Payment Methods" section.</h4>
                    <h4 class="subtitle">If you encounter any issues or have questions regarding your payment, our dedicated support team is here to assist you.</h4>
                    <h4 class="subtitle">Feel free to reach out to us at <a href="mailto:contactus@gocoderz.com.">contactus@gocoderz.com</a>, and we'll be happy to help.</h4>
            </div>
              <div v-else-if="failType === 'user'" class="content">
                  <h2 class="title">Thank you for your recent order with CoderZ!</h2>
                  <h4 class="subtitle">
                    Your payment was successful!</h4>
                    <h4 class="subtitle">Our team is working to create your account.</h4>
                    <h4 class="subtitle">For any questions or to expedite the process, email us at <a href="mailto:contactus@gocoderz.com.">contactus@gocoderz.com</a>.</h4>
              </div>
          </div>
      </div>
      <ul class="shape-group">
          <li class="shape-1">
              <img src="/images/others/map-shape-2.png" alt="Shape">
          </li>
      </ul>
  </section>
  </div>
</template>

<script>
export default {
  name: 'FailView',
  components: {
  },
  computed: {
    failType () {
      const { query } = this.$route
      if (!query || !query.type) {
        return 'payment'
      }

      return query.type
    }
  }
}
</script>

<style lang="scss">
.fail-page {
  .title, .subtitle {
    margin-bottom: 32px !important;
  }

  .title {
    margin-top: 32px !important;
  }

  .subtitle {
    a {
      color: #f72887;
      text-decoration: underline;

      &:hover {
        color: #ff5b5c;
      }
    }
  }
}
</style>
