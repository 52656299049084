import { request } from 'coderz-utils/index-no-deps'

class Geo {
  static getCountries () {
    return request({ url: '@api/geography/get-countries' })
  }

  static getCountry ({ countryCode }) {
    if (!countryCode) { return '' }

    return request({
      url: '@api/geography/get-country',
      data: {
        countryAlpha2Code: countryCode
      }
    })
  }

  static getStates ({ countryCode }) {
    return request({
      url: '@api/geography/get-states-by-country',
      data: {
        countryAlpha2Code: countryCode
      }
    })
  }

  static getState ({ countryCode, stateCode }) {
    if (!stateCode) { return Promise.resolve('') }
    return request({
      url: '@api/geography/get-state',
      data: {
        countryAlpha2Code: countryCode,
        stateCode
      }
    })
  }

  static getDistricts ({ countryCode, stateCode }) {
    return request({
      url: '@api/district/get-all-by-stateId',
      data: {
        stateId: `${countryCode}-${stateCode}`
      }
    })
  }

  static getDistrict ({ countryCode, stateCode, districtCode }) {
    return request({
      url: '@api/geography/get-district',
      data: {
        countryCode,
        stateCode,
        districtCode
      }
    })
  }

  static getSchoolsByCountry ({ searchPattern, countryAlpha2Code }) {
    return request({
      url: '@api/geography/get-schools-by-country-and-state',
      data: {
        searchPattern,
        countryAlpha2Code
      }
    })
  }

  static getCountryAndState () {
    return request({ url: '@api/geography/get-country-and-state' })
  }
}

export default Geo
