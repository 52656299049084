import axios from 'axios'
import DomainName from './domain'

// create an axios instance
const service = axios.create({
  baseURL: '/api', // process.env.VUE_APP_BASE_API, // url = base url + request url
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 30 * 1000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  (config) => {
      config.method = config.overrideMethod ? config.overrideMethod : "post";

    if (config.url.startsWith('@api')) {
      config.baseURL = `${window.location.protocol}//api.${DomainName}`
      config.url = config.url.replace('@api', '/api')
    }

    if (config.url.startsWith('@content')) {
      config.baseURL = `${window.location.protocol}//content.${DomainName}`
      config.url = config.url.replace('@content', '/api')
    }
    if (config.url.startsWith('@league')) {
      config.baseURL = `${window.location.protocol}//league.${DomainName}`
      config.url = config.url.replace('@league', '/api')
    }
    if (config.url.startsWith('@store')) {
      config.baseURL = `${window.location.protocol}//store.${DomainName}`
      config.url = config.url.replace('@store', '/api')
    }
    if (config.url.startsWith('@events')) {
      config.baseURL = `${window.location.protocol}//events.${DomainName}`
      config.url = config.url.replace('@events', '/api')
    }
    // do something before request is sent

    return config
  },
  (error) => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

  /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
  (response) => {
    const res = response.data

    // if the custom code is not 20000, it is judged as an error.
    /* if (res.code !== 20000) {

      Message({
        message: res.message || 'Error',
        type: 'error',
        duration: 5 * 1000
      })

      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
        // to re-login
        MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
          confirmButtonText: 'Re-Login',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }).then(() => {
          store.dispatch('user/resetToken').then(() => {
            location.reload()
          })
        })
      }
      return Promise.reject(new Error(res.message || 'Error'))
    } else { */
    return res
    // }
  },
  (error) => {
    console.log('API ERROR', error)

    return Promise.reject(error.response?.data || error.message)
  }
)

export default service
