<template>
  <div id="main-wrapper" class="main-wrapper">
      <div class="edu-breadcrumb-area">
        <div class="container">
            <div class="breadcrumb-inner">
                <div class="page-title">
                    <h1 class="title">Checkout</h1>
                </div>
                <ul class="edu-breadcrumb">
                    <li class="breadcrumb-item">
                        <RouterLink to="/">Products</RouterLink>
                    </li>
                    <li class="separator"><i class="icon-angle-right"></i></li>
                    <li class="breadcrumb-item">
                        <RouterLink to="/cart">Cart</RouterLink>
                    </li>
                    <li class="separator"><i class="icon-angle-right"></i></li>
                    <li class="breadcrumb-item active" aria-current="page">Checkout</li>
                </ul>
            </div>
        </div>
    </div>

      <section class="checkout-page-area section-gap-equal">
          <div class="container">
              <form>
                  <div class="row row--25">
                      <div class="col-lg-6">
                          <div class="checkout-billing">
                              <h3 class="title">Contact information</h3>
                              <div class="row">
                                <div class="col-lg-6">
                                  <div class="form-group">
                                    <label>First Name</label>
                                    <input :class="{'is-invalid': $v.userFirstName.$error, 'is-disabled': isUserLogined }" v-model="$v.userFirstName.$model" type="text" id="first-name" :readonly="isUserLogined" @change="changeUserFormField('firstName', $event)" required>
                                    <div class="invalid-feedback">
                                      Please enter first name
                                    </div>
                                  </div>
                                </div>
                                <div class="col-lg-6">
                                  <div class="form-group">
                                      <label>Last Name</label>
                                      <input :class="{'is-invalid': $v.userLastName.$error, 'is-disabled': isUserLogined }" v-model="$v.userLastName.$model"  type="text" id="last-name" :readonly="isUserLogined" @change="changeUserFormField('lastName', $event)" required>
                                      <div class="invalid-feedback">
                                        Please enter last name
                                      </div>
                                  </div>
                                </div>
                              </div>
                              <div class="form-group">
                                  <label>Email</label>
                                  <input :class="{'is-invalid': $v.userEmail.$error, 'is-disabled': isUserLogined }" v-model="$v.userEmail.$model" type="email" id="email" :readonly="isUserLogined" @change="changeUserFormField('email', $event)" required>
                                  <div class="invalid-feedback">
                                    Please enter valid email
                                  </div>
                              </div>
                              <div v-if="emailError" class="form-group">
                                <div class="alert alert-success" role="alert">
                                  <strong>Hey!</strong>
                                  We know you! This email was used before on our platform. Please login to your account to continue this application process.
                                </div>
                                <button type="button" class="edu-btn" @click="login">Log in</button>
                              </div>
                              <div v-if="isUserLogined" class="form-group">
                                  <label>School</label>
                                  <Multiselect :class="{'is-invalid': $v.selectedSchool.$error}" :internal-search="false" @search-change="findUserSchool" @input="changeFormField('selectedSchool', $event)" v-model="$v.selectedSchool.$model" :multiple="false" :show-labels="false" label="name" track-by="id" :options="filteredUserSchools" placeholder="Select school" />
                                  <div class="invalid-feedback">
                                    Please select a school
                                  </div>
                              </div>
                              <template v-if="isPrivateSelected || !isUserLogined">
                                <!--<div class="form-group">
                                  <label>Country</label>
                                  <Multiselect :class="{'is-invalid': $v.schoolCountry.$error}" @input="changeFormField('schoolCountry', $event)" v-model="$v.schoolCountry.$model" :multiple="false" :show-labels="false"  :options="countries" placeholder="Select country" />
                                  <div class="invalid-feedback">
                                    Please select a country
                                  </div>
                                </div>-->
                                <template v-if="schoolCountry === 'US'">
                                  <div class="form-group">
                                    <label>Find school</label>
                                    <Multiselect
                                      v-model="$v.selectedSchoolUS.$model"
                                      :class="{'is-invalid': $v.selectedSchoolUS.$error}"
                                      placeholder="Type to search"
                                      open-direction="bottom"
                                      label="name"
                                      :options="usSchoolOptions"
                                      :multiple="false"
                                      :searchable="true"
                                      track-by="id"
                                      :loading="isSchoolSearchLoading"
                                      :internal-search="false"
                                      :clear-on-select="false"
                                      :close-on-select="true"
                                      :max-height="600"
                                      :show-no-results="true"
                                      @search-change="findUSSchool"
                                      @input="changeFormField('selectedSchoolUS', $event)"
                                    >
                                      <span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
                                      <span slot="noOptions">Start typing school name</span>
                                    </Multiselect>
                                    <div class="invalid-feedback">
                                      Please select a school
                                    </div>
                                  </div>
                                  <template v-if="showNCES">
                                    <div class="form-group">
                                      <label>School state</label>
                                      <Multiselect v-model="$v.schoolState.$model" :class="{'is-invalid': $v.schoolState.$error}" :multiple="false" :show-labels="false" label="name" track-by="id" :options="schoolStates" placeholder="Select state" />
                                      <div class="invalid-feedback">
                                        Please select a state
                                      </div>
                                    </div>
                                    <div class="row">
                                      <div class="col-lg-6">
                                          <div class="form-group">
                                            <label>School name</label>
                                            <input :class="{'is-invalid': $v.schoolName.$error}" v-model="$v.schoolName.$model" placeholder="School name" type="text" id="school-name" />
                                            <div class="invalid-feedback">
                                              Please enter a school name
                                            </div>
                                          </div>
                                      </div>
                                      <div class="col-lg-6">
                                          <div class="form-group">
                                            <label>District</label>
                                            <input :class="{'is-invalid': $v.schoolDistrict.$error}" v-model="$v.schoolDistrict.$model" placeholder="District" type="text" id="district-name" />
                                            <div class="invalid-feedback">
                                              Please enter a district name
                                            </div>
                                          </div>
                                      </div>
                                    </div>
                                    <div class="form-group">
                                      <label>NCES</label>
                                      <input v-model="$v.schoolNCES.$model" placeholder="NCES" type="text" id="nces" />
                                    </div>
                                  </template>
                                </template>
                              </template>
                              <div class="form-group">
                                <div class="form-check">
                                  <input class="form-check-input" :class="{'is-invalid': $v.termsAccepted.$error}" type="checkbox" v-model="$v.termsAccepted.$model" id="termsAccepted" />
                                  <label class="form-check-label" for="termsAccepted">
                                    <span class="accept-terms-txt">
                                      I accept the <a href="https://gocoderz.com/terms-of-service/" target="_blank">Terms of Use</a> &amp; <a target="_blank" href="https://gocoderz.com/privacy-policy/">Privacy Policy</a>
                                    </span>
                                  </label>
                                  <div class="invalid-feedback">
                                    Please accept Terms of Use and Privacy Policy
                                  </div>
                                </div>
                              </div>
                          </div>
                      </div>
                      <div class="col-lg-6">
                            <div class="order-summery checkout-summery">
                                <div class="summery-table-wrap">
                                    <h4 class="title">{{ $t('Your order summary') }}</h4>
                                    <table class="table summery-table">
                                        <tbody>
                                            <tr v-for="product in userCart" :key="product.sku">
                                                <td>{{ product.productCourse.product.name }} <span class="quantity">x {{ product.quantity }}</span></td>
                                                <td>${{ product.total }}</td>
                                            </tr>
                                            <template v-if="userOrder">
                                              <tr class="order-subtotal">
                                                  <td>Sub Total</td>
                                                  <td>${{ userOrder.subtotal }}</td>
                                              </tr>
                                              <template v-if="userOrder.discounts && userOrder.discounts.length">
                                                <tr v-for="d in userOrder.discounts" :key="d.id" class="order-total">
                                                    <td>{{ d.description }}</td>
                                                    <td>-${{  d.amount  }}</td>
                                                </tr>
                                              </template>
                                              <tr class="order-total">
                                                  <td>Order Total</td>
                                                  <td>${{ userOrder.total }}</td>
                                              </tr>
                                          </template>
                                        </tbody>
                                    </table>
                                    <!--<div v-if="userOrder && !isNullSubtotal" class="cart-update-btn-area">
                                      <div class="input-group product-cupon">
                                          <input placeholder="Coupon code..." type="text">
                                          <button type="submit" class="submit-btn"><i class="icon-4"></i></button>
                                      </div>
                                  </div>-->
                                </div>
                            </div>
                            <div v-if="!isNullTotal && isUserSet" class="order-payment" style="margin-top: 0;">
                                <h4 class="title">Payment</h4>
                                <div class="payment-method">
                                  <div class="form-group">
                                        <div class="edu-form-check">
                                            <input :disabled="!isUserSet" value="getAQuote" v-model="paymentType" type="radio" id="pay-quote" name="payment">
                                            <label for="pay-quote">Get a Quote</label>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div class="edu-form-check">
                                            <input :disabled="!isUserSet" value="stripe" v-model="paymentType" type="radio" id="pay-stripe" name="payment" @click="getPaymentTokenIfNeeded">
                                            <label for="pay-stripe">Credit Card</label>
                                        </div>
                                    </div>
                                    <stripe-element-payment
                                      v-if="hasPaymentToken"
                                      v-show="paymentType === 'stripe'"
                                      ref="paymentRef"
                                      @error="handleStripeError"
                                      :pk="stripeKey"
                                      :elements-options="elementsOptions"
                                      :confirm-params="confirmParams"
                                    />
                                </div>
                                <div v-if="paymentType === 'stripe' && stripeError" class="alert alert-danger" role="alert">
                                  {{  $t('Payment error') }}: {{ stripeError.message }}
                                </div>
                                <div class="place-order-row">
                                <a @click.stop.prevent="payStripe" v-if="paymentType === 'stripe'" class="edu-btn order-place"  :class="{'edu-btn--disabled': isOrderLoading}">Place an order<i class="icon-4"></i></a>
                                <a @click.stop.prevent="getQuote" v-if="paymentType === 'getAQuote'" class="edu-btn order-place" :class="{'edu-btn--disabled': isOrderLoading}">Place an order<i class="icon-4"></i></a>
                              </div>
                          </div>
                          <div class="order-payment" style="margin-top: 0;" v-else-if="isUserSet">
                            <div class="place-order-row">
                            <a @click.stop.prevent="submitRegister" class="edu-btn order-place" :class="{'edu-btn--disabled': isOrderLoading}">Place an order<i class="icon-4"></i></a>
                          </div>
                          </div>
                        </div>
                  </div>
              </form>
          </div>
      </section>
  </div>
</template>

<script>
import { StripeElementPayment } from '@vue-stripe/vue-stripe'

import { required, email } from 'vuelidate/lib/validators'

import GeoApi from '@/api/geo'
import Multiselect from 'vue-multiselect'

import { DomainName, routingUtils } from 'coderz-utils/index-no-deps'
const SUCCESS_URL = `${window.location.protocol}//store.${DomainName}/api/order/after-payment`

const US_SCHOOLS_TEMPLATE = [
  {
    name: 'Can\'t find my school',
    id: 'cant-find'
  }
]

const VALIDATORS_USER = {
  userFirstName: {
    required
  },
  userLastName: {
    required
  },
  userEmail: {
    required,
    email
  },
  termsAccepted: {
    checked: value => value === true
  }
}

const PRIVATE_ACCOUNT_OPTION = {
  name: 'Create your organization',
  id: 'private'
}

const getSchoolPayload = (vm) => {
  const ret = {}

  if (vm.isUserLogined) {
    ret.usePrivateAccount = !!vm.isPrivateSelected
    if (!vm.isPrivateSelected) {
      ret.groupId = vm.selectedSchool.id
      return ret
    }
  }

  ret.country = vm.schoolCountry

  if (vm.selectedSchoolUS.id === 'cant-find') {
    ret.state = vm.schoolState.id.split('-').pop()
    ret.schoolName = vm.schoolName
    if (vm.schoolNCES && vm.schoolNCES.length) {
      ret.schoolId = vm.schoolNCES
    }
    ret.district = vm.schoolDistrict
  } else {
    const { state, name, id, school_district_name: district } = vm.selectedSchoolUS
    ret.state = state
    ret.schoolName = name
    ret.schoolId = id
    ret.district = district
  }

  return ret
}

export default {
  components: {
    Multiselect,
    StripeElementPayment
  },
  data () {
    return {
      usSchoolOptions: [],
      isSchoolSearchLoading: false,
      countries: ['US'],
      isFormLoading: false,
      schoolStates: [],
      filteredUserSchools: [],
      paymentType: 'getAQuote',
      stripeKey: process.env.VUE_APP_STRIPE_KEY,
      elementsOptions: {
        appearance: {
          theme: 'night'
        }
      },
      confirmParams: {
        return_url: SUCCESS_URL
      },
      stripeError: null,
      termsAccepted: false,
      isUserSetting: false,
      emailError: null
    }
  },
  validations () {
    const ret = {
      ...VALIDATORS_USER
    }

    const schoolForm = {
    }

    if (this.isUserLogined) {
      schoolForm.selectedSchool = {
        required
      }
    }

    if (this.isPrivateSelected || !this.isUserLogined) {
      schoolForm.schoolCountry = {
        required
      }

      if (this.schoolCountry === 'US') {
        schoolForm.selectedSchoolUS = {
          required
        }

        if (this.selectedSchoolUS && this.selectedSchoolUS.id === 'cant-find') {
          schoolForm.schoolState = {
            required
          }

          schoolForm.schoolName = {
            required
          }

          schoolForm.schoolNCES = {
          }

          schoolForm.schoolDistrict = {
            required
          }
        }
      }
    }

    return {
      ...ret,
      ...schoolForm
    }
  },
  computed: {
    hasPaymentToken () {
      return this.$store.state.cart.paymentToken
    },
    isUserLogined () {
      return this.$store.state.user.isLogined
    },
    isOrderLoading () {
      return this.$store.state.cart.isOrderLoading
    },
    isPrivateSelected () {
      return this.selectedSchool && (this.selectedSchool.id === 'private' || (this.selectedSchool.code && this.selectedSchool.code.includes('private-')))
    },
    isUserSet () {
      return this.$store.state.user.isUserSet
    },
    userCart () {
      return this.$store.state.cart.userCart.map((ci) => {
        return {
          ...ci,
          productCourse: this.$store.state.courses.coursesList.find((course) => course.sku === ci.sku)
        }
      })
    },
    userOrder () {
      return this.$store.state.cart.order
    },
    cartTotal () {
      return this.userOrder.total
    },
    isNullTotal () {
      return this.cartTotal === 0
    },
    isNullSubtotal () {
      return this.userOrder.subtotal === 0
    },
    userSchools () {
      return [
        ...this.$store.state.user.userSchools.filter((s) => (s.year_cycle === 8) && (!s.code || !s.code.includes('private-')))
      ]
    },
    showNCES () {
      return (this.isPrivateSelected && this.selectedSchoolUS && this.selectedSchoolUS.id === 'cant-find') || (!this.isUserLogined && this.selectedSchoolUS && this.selectedSchoolUS.id === 'cant-find')
    },
    userEmail: {
      get () {
        return this.$store.state.user.userFormData.email
      },
      set (value) {
        this.$store.commit('user/SET_USER_FORM_VALUE', { key: 'email', value })
      }
    },
    userFirstName: {
      get () {
        return this.$store.state.user.userFormData.firstName
      },
      set (value) {
        this.$store.commit('user/SET_USER_FORM_VALUE', { key: 'firstName', value })
      }
    },
    userLastName: {
      get () {
        return this.$store.state.user.userFormData.lastName
      },
      set (value) {
        this.$store.commit('user/SET_USER_FORM_VALUE', { key: 'lastName', value })
      }
    },
    selectedSchool: {
      get () {
        return this.$store.state.user.schoolFormData.selectedSchool
      },
      set (value) {
        this.$store.commit('user/SET_SCHOOL_FORM_VALUE', { key: 'selectedSchool', value })
      }
    },
    schoolCountry: {
      get () {
        return this.$store.state.user.schoolFormData.schoolCountry
      },
      set (value) {
        this.$store.commit('user/SET_SCHOOL_FORM_VALUE', { key: 'schoolCountry', value })
      }
    },
    selectedSchoolUS: {
      get () {
        return this.$store.state.user.schoolFormData.selectedSchoolUS
      },
      set (value) {
        this.$store.commit('user/SET_SCHOOL_FORM_VALUE', { key: 'selectedSchoolUS', value })
      }
    },
    schoolName: {
      get () {
        return this.$store.state.user.schoolFormData.schoolName
      },
      set (value) {
        this.$store.commit('user/SET_SCHOOL_FORM_VALUE', { key: 'schoolName', value })
      }
    },
    schoolState: {
      get () {
        return this.$store.state.user.schoolFormData.schoolState
      },
      set (value) {
        this.$store.commit('user/SET_SCHOOL_FORM_VALUE', { key: 'schoolState', value })
      }
    },
    schoolNCES: {
      get () {
        return this.$store.state.user.schoolFormData.schoolNCES
      },
      set (value) {
        this.$store.commit('user/SET_SCHOOL_FORM_VALUE', { key: 'schoolNCES', value })
      }
    },
    schoolDistrict: {
      get () {
        return this.$store.state.user.schoolFormData.schoolDistrict
      },
      set (value) {
        this.$store.commit('user/SET_SCHOOL_FORM_VALUE', { key: 'schoolDistrict', value })
      }
    }
  },
  mounted () {
    if (this.schoolCountry) {
      this.updateStates()
    }

    if (this.isUserSet) {
      this.$store.dispatch('cart/getOrder')
    }

    if (this.isUserLogined) {
      this.filteredUserSchools = [
        ...this.userSchools,
        PRIVATE_ACCOUNT_OPTION
      ]
    }
  },
  methods: {
    login () {
      routingUtils.goBackToLogin('/checkout')
    },
    async getPaymentTokenIfNeeded () {
      if (!this.$store.state.cart.paymentToken) {
        await this.$store.dispatch('cart/getPaymentToken')
        this.elementsOptions.clientSecret = this.$store.state.cart.paymentToken
      }
    },
    handleStripeError (e) {
      console.log('error', e)
      if (e.type !== 'validation_error') {
        this.stripeError = e
      }
    },
    async payStripe () {
      this.stripeError = null
      this.$v.$touch()
      if (this.$v.$invalid) {
        console.log('invalid')
      } else {
        const schoolPayload = getSchoolPayload(this)
        await this.$store.dispatch('cart/setSchoolData', schoolPayload)
        const { action, value } = await this.$store.dispatch('cart/placeOrder')
        if (action === 'next') {
          this.$refs.paymentRef.submit()
        } else if (action === 'redirect') {
          window.location.href = value
        }
      }
    },
    async getQuote () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        console.log('invalid!')
        console.log(this.$v)
      } else {
        const schoolPayload = getSchoolPayload(this)
        await this.$store.dispatch('cart/setSchoolData', schoolPayload)
        const { action, value } = await this.$store.dispatch('cart/placeOrder')
        if (action === 'next') {
          const result = await this.$store.dispatch('cart/requestQuote')
          window.location.href = result
        } else if (action === 'redirect') {
          window.location.href = value
        }
      }
    },
    async submitRegister () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        console.log('invalid!')
        console.log(this.$v)
      } else {
        const schoolPayload = getSchoolPayload(this)
        await this.$store.dispatch('cart/setSchoolData', schoolPayload)
        const { action, value } = await this.$store.dispatch('cart/placeOrder')
        if (action === 'redirect') {
          window.location.href = value
        }
      }
    },
    async updateStates () {
      this.isFormLoading = true
      const { data } = await GeoApi.getStates({
        countryCode: this.schoolCountry
      })
      this.schoolStates = data
      this.isFormLoading = false
    },
    async findUSSchool (searchPattern) {
      if (searchPattern.length < 3) {
        this.usSchoolOptions = [...US_SCHOOLS_TEMPLATE]
        if (this.selectedSchoolUS && this.selectedSchoolUS.id !== 'cant-find') {
          this.usSchoolOptions.unshift(this.selectedSchoolUS)
        }
        return
      }
      this.isSchoolSearchLoading = true
      const result = await GeoApi.getSchoolsByCountry({
        searchPattern,
        countryAlpha2Code: 'US'
      })
      if (result) {
        this.usSchoolOptions = [...result, ...US_SCHOOLS_TEMPLATE]
      } else {
        this.usSchoolOptions = [...US_SCHOOLS_TEMPLATE]
      }

      this.isSchoolSearchLoading = false
    },
    findUserSchool (searchPattern) {
      let privateAccountOption = this.$store.state.user.userSchools.find((s) => s.code && s.code.includes('private-'))
      if (!privateAccountOption || privateAccountOption.year_cycle !== 8) {
        privateAccountOption = PRIVATE_ACCOUNT_OPTION
      } else {
        privateAccountOption = {
          ...privateAccountOption,
          name: 'My Organization'
        }
      }

      if (searchPattern && searchPattern.length > 2) {
        const schools = this.userSchools.filter((s) => (!s.code || !s.code.includes('private-')) && s.name.toLowerCase().includes(searchPattern.toLowerCase()))
        this.filteredUserSchools = [
          ...schools,
          privateAccountOption
        ]
      } else {
        this.filteredUserSchools = [
          ...this.userSchools,
          privateAccountOption
        ]
      }
    },
    changeFormField (fieldName, value) {
    },
    async changeUserFormField (fieldName, value) {
      if (this.isUserLogined) {
        return
      }

      if (fieldName === 'email') {
        this.emailError = false
      }

      const isEmailValid = !this.$v.userEmail.$invalid
      const isFirstNameValid = !this.$v.userFirstName.$invalid
      const isLastNameValid = !this.$v.userLastName.$invalid

      if (isEmailValid && isFirstNameValid && isLastNameValid) {
        this.isUserSetting = true
        const { state, result, error } = await this.$store.dispatch('user/setUser', {
          firstName: this.userFirstName,
          lastName: this.userLastName,
          email: this.userEmail
        })
        if (state === 'SUCCESS' && result) {
          this.$store.dispatch('cart/getOrder')
        } else {
          if (error.code === 2005) {
            this.emailError = true
          }
          console.log('SET ERROR', error)
        }
        this.isUserSetting = false
      }
    }
  },
  beforeRouteLeave (to, from, next) {
    this.$store.commit('cart/SET_PAYMENT_TOKEN', null)
    next()
  }
}
</script>

<style lang="scss">
.multiselect {
  &__input {
    border: none !important;
    border-radius: 0 !important;
    padding: 0 !important;
    height: 42px !important;
  }

  &__tags {
    padding: 0 25px;
    border-color: var(--dark-color-border);
    background-color: var(--color-heading);
    height: 45px;
    box-sizing: border-box;
    color: var(--color-white);
  }

  &__placeholder {
    font-family: var(--font-secondary);
    font-size: 15px;
    margin-top: 0px;
    color: var(--color-body);
    padding-top: 0;
    position: absolute;
    top: calc(50% - 12px);
  }

  &__single {
    font-family: var(--font-secondary);
    color: var(--color-body);
    font-size: 15px;
    background-color: var(--color-heading);
    padding-top: 0;
    position: absolute;
    top: calc(50% - 10px);
    left: 20px;
    width: calc(100% - 80px);
    color: var(--color-white);
  }
}

.payment-method {
  margin-bottom: 0;

  .form-group:last-child {
    margin-top: 16px;
  }
}

.checkout-billing {
  input.is-disabled {
    opacity: 0.5;
  }
}

.email-error {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.order-place {
  cursor: pointer;
}

.place-order-row {
  display: flex;
  justify-content: end;
}
</style>
