import { request } from 'coderz-utils/index-no-deps'

class Order {
  static getPaymentToken () {
    return request({ url: '@store/order/get-payment-token' })
  }

  static getOrder () {
    return request({ url: '@store/order/get' })
  }

  static placeOrder () {
    return request({ url: '@store/order/place' })
  }

  static requestQuote () {
    return request({ url: '@store/order/request-quote' })
  }

  static setSchoolData ({ schoolName, state, country, schoolId, usePrivateAccount, groupId, district }) {
    const payload = {
      schoolName,
      state,
      country,
      schoolId,
      usePrivateAccount,
      district
    }

    if (!usePrivateAccount) {
      payload.groupId = groupId
    }

    return request({
      url: '@store/order/set-school-data',
      data: payload
    })
  }
}

export default Order
