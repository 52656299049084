import VueI18n from 'vue-i18n'

import {
  loadLanguageAsync,
  getLanguages,
  setLanguage,
  getLanguageByCode,
  TranslateEventBus,
  i18n
} from './src/translate'

import {
  goBackToLogin,
  redirect
} from './src/routing'

import { isString, isArray } from './src/validate'

import { camelToSnakeCase } from './src/utils'

import Auth from './src/auth'
import Cookie from './src/cookie'
import DomainName from './src/domain'
import request from './src/request'

const CoderzPlugin = {
  install (Vue, options) {
    Vue.use(VueI18n)
    Vue.prototype.$coderz = {
      getLanguages: () => {
        return getLanguages()
      },
      setLanguage: async (lang) => {
        await setLanguage(lang)
      },
      getLanguageByCode: (lang) => {
        return getLanguageByCode(lang)
      },
      camelToSnakeCase: (s) => camelToSnakeCase(s),
      TranslateEventBus
    }
  }
}

const utils = {
  camelToSnakeCase
}

const translationUtils = {
  i18n,
  loadLanguageAsync,
  setLanguage,
  getLanguageByCode,
  getLanguages
}

const routingUtils = {
  goBackToLogin,
  redirect
}

const validationUtils = {
  isString,
  isArray
}

export {
  translationUtils,
  routingUtils,
  validationUtils,
  request,
  utils,
  Auth,
  Cookie,
  DomainName
}

export default CoderzPlugin
