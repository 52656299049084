import Vue from 'vue'
import CartApi from '@/api/cart'
import OrderApi from '@/api/order'

function debounce (callback, delay = 300) {
  let timeout = null
  return (...args) => {
    clearTimeout(timeout)
    const context = this
    timeout = setTimeout(() => callback.apply(context, args), delay)
  }
}

const DEFAULT_STATE = {
  userCart: [],
  cartMeta: {},
  total: 0,
  paymentToken: null,
  order: {},
  isOrderLoading: false,
  isCartLoading: false
}

const mutations = {
  ADD_TO_CART (state, payload) {
    state.userCart.push(payload)
    Vue.set(state.cartMeta, payload.sku, {
      quantity: 1
    })
  },
  REMOVE_FROM_CART (state, payload) {
    const idx = state.userCart.findIndex((c) => c.sku === payload.sku)
    if (idx >= 0) {
      state.userCart.splice(idx, 1)
    }

    delete state.cartMeta[payload.sku]
  },
  INCREASE_QUANTITY (state, payload) {
    const idx = state.userCart.findIndex((c) => c.sku === payload.sku)
    state.userCart[idx].quantity += 1
  },
  DECREASE_QUANTITY (state, payload) {
    const idx = state.userCart.findIndex((c) => c.sku === payload.sku)
    if (state.userCart[idx].quantity === 1) {
      return
    }
    state.userCart[idx].quantity -= 1
  },
  UPDATE_SCHOOL_YEAR (state, { product, value }) {
    const idx = state.userCart.findIndex((c) => c.sku === product.sku)
    state.userCart[idx].schoolYear = value.map((sy) => sy.value)
  },
  SET_USER_CART (state, payload) {
    state.userCart = payload || []
  },
  SET_CART_TOTAL (state, payload) {
    state.total = payload || 0
  },
  SET_PAYMENT_TOKEN (state, payload) {
    state.paymentToken = payload
  },
  SET_ORDER (state, payload) {
    state.order = payload
  },
  SET_ORDER_LOADING (state, payload) {
    state.isOrderLoading = payload
  },
  SET_CART_LOADING (state, payload) {
    state.isCartLoading = payload
  }
}

const debouncedUpdateQuantity = debounce(async ({ commit, state, payload }) => {
  const idx = state.userCart.findIndex((c) => c.sku === payload.sku)
  commit('SET_CART_LOADING', true)
  const { items, total } = await CartApi.updateCartQuantity({ sku: payload.sku, quantity: state.userCart[idx].quantity })
  commit('SET_CART_LOADING', false)
  commit('SET_USER_CART', items)
  commit('SET_CART_TOTAL', total)
}, 1000)

const debouncedUpdateSchoolYear = debounce(async ({ commit, state, product, isValid }) => {
  const idx = state.userCart.findIndex((c) => c.sku === product.sku)
  if (isValid) {
    commit('SET_CART_LOADING', true)
    const { items, total } = await CartApi.updateCartSchoolYear({ sku: product.sku, schoolYear: state.userCart[idx].schoolYear })
    commit('SET_CART_LOADING', false)
    commit('SET_USER_CART', items)
    commit('SET_CART_TOTAL', total)
  }
}, 1000)

const actions = {
  async addToCart ({ commit }, payload) {
    commit('ADD_TO_CART', payload)
    const { items, total } = await CartApi.addToCart(payload)
    commit('SET_USER_CART', items)
    commit('SET_CART_TOTAL', total)
  },
  async removeFromCart ({ commit }, payload) {
    commit('REMOVE_FROM_CART', payload)
    const { items, total } = await CartApi.removeFromCart(payload)
    commit('SET_USER_CART', items)
    commit('SET_CART_TOTAL', total)
  },
  async increaseQuantity ({ commit, state }, payload) {
    commit('INCREASE_QUANTITY', payload)
    debouncedUpdateQuantity({ state, commit, payload })
  },
  async decreaseQuantity ({ commit, state }, payload) {
    commit('DECREASE_QUANTITY', payload)
    debouncedUpdateQuantity({ state, commit, payload })
  },
  async getCart ({ commit }) {
    const { items, total } = await CartApi.getCart()
    commit('SET_USER_CART', items)
    commit('SET_CART_TOTAL', total)
  },
  async getPaymentToken ({ commit }) {
    commit('SET_ORDER_LOADING', true)
    const res = await OrderApi.getPaymentToken()
    commit('SET_ORDER_LOADING', false)
    commit('SET_PAYMENT_TOKEN', res)
    return res
  },
  async getOrder ({ commit }) {
    commit('SET_ORDER_LOADING', true)
    const res = await OrderApi.getOrder()
    commit('SET_ORDER', res)
    commit('SET_ORDER_LOADING', false)
    return res
  },
  async placeOrder ({ commit }) {
    commit('SET_ORDER_LOADING', true)
    const res = await OrderApi.placeOrder()
    commit('SET_ORDER_LOADING', false)
    return res
  },
  async requestQuote ({ commit }) {
    commit('SET_ORDER_LOADING', true)
    const res = await OrderApi.requestQuote()
    commit('SET_ORDER_LOADING', false)
    return res
  },
  async setSchoolData ({ commit }, { schoolName, state, country, schoolId, usePrivateAccount, groupId, district }) {
    const res = await OrderApi.setSchoolData({ schoolName, state, country, schoolId, usePrivateAccount, groupId, district })
    return res
  },
  async updateSchoolYear ({ commit, state }, { product, value, isValid }) {
    commit('UPDATE_SCHOOL_YEAR', { product, value })
    debouncedUpdateSchoolYear({ product, isValid, state, commit })
  }
}

export default {
  namespaced: true,
  state: DEFAULT_STATE,
  mutations,
  actions
}
