import { request } from 'coderz-utils/index-no-deps'

class Cart {
  static getCart () {
    return request({ url: '@store/cart/get' })
  }

  static addToCart ({ sku }) {
    return request({ url: '@store/cart/add', data: { sku } })
  }

  static removeFromCart ({ sku }) {
    return request({ url: '@store/cart/remove', data: { sku } })
  }

  static updateCartQuantity ({ sku, quantity }) {
    return request({ url: '@store/cart/update/quantity', data: { sku, quantity } })
  }

  static updateCartSchoolYear ({ sku, schoolYear }) {
    return request({ url: '@store/cart/update/school-year', data: { sku, schoolYear } })
  }
}

export default Cart
